.fundingsummary__title {
    color: var(--Space-Cadet, #17183B);
    font-family: 'Nunito', sans-serif;
    font-size: 1.5rem; // Converted from 2.4rem
    font-style: normal;
    font-weight: 700;
    line-height: 1.6rem; // Converted from 3.2rem
}

.fundingsummary__section {
    p {
        color: var(--Space-Cadet, #17183B);
        font-family: 'Nunito', sans-serif;
        font-size: 1rem; // Converted from 1.6rem
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem; // Converted from 2.4rem

        &.total {
            font-weight: 600;
        }
    }

    h4 {
        color: var(--Space-Cadet, #17183B);
        font-family: 'Nunito', sans-serif;
        font-size: 1.125rem; // Converted from 1.8rem
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem; // Converted from 2.4rem
    }
}

.fundingsummary__button {
    display: flex;
    height: 3.625rem; // Converted from 5.8rem
    padding: 0.9rem 2.5rem; // Converted from 1.8rem 4rem
    justify-content: center;
    align-items: center;
    gap: 1rem; // Converted from 1.6rem
    align-self: stretch;
    border-radius: 6.25rem; // Converted from 10rem to 6.25rem
    background: #246BFD;
    color: var(--White, #FFF);
    font-family: 'Nunito', sans-serif;
    font-size: 1rem; // Converted from 1.6rem
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; // Converted from 2rem
    letter-spacing: 0.01rem;
    width: 100%;
}
