@use '../../styles/mixin' as * ;

.profile__container {
    padding-block-start: 3.125rem;
    padding-block-end: 3.125rem;
    padding-inline-start: 2.5rem;
    padding-inline-end: 6.25rem;
    max-width: 60.8125rem;
    @include mobile{
        padding: 1rem;
    }
}

.header {
    h1 {
        color: #1B212D;
        font-size: 1.25rem;
        font-weight: 600;
    }

    p {
        color: #929EAE;
        font-size: 0.875rem;
        font-weight: 400;
    }
}

.edit {
    cursor: pointer;
    display: flex;
    gap: 0.625rem;
    align-items: center;
    position: relative;

    span {
        color: var(--primary);
        font-size: 1.125rem;
        font-weight: 600;
    }
}

.personal_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block-start: 2.5rem;
    padding-block-end: 2.5rem;

    h1 {
        color: #1B212D;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.form__input__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 2.6875rem;
    padding-block-end: 2.125rem;
    @include mobile{
        grid-template-columns: 1fr;
    }

}

span.edit-message {
    font-size: 0.75rem;
    color: gray;
    position: absolute;
    top: -1.875rem;
    left: 0;
    width: max-content;
    background-color: #fff;
    padding: 0.3125rem 0.375rem;
    padding-block-end: 0.5rem;
    border-radius: 0.625rem;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
}
