@use '../styles/mixin' as *;
@import './variables';
@import './reset';

body {
    width: 100%;
    height: 100%;
    max-width: 190em;
    margin: 0 auto;
    background: #F5F5F5;
}

button{
    outline: none;
    border: none;
}


img {
    width: 100%;
    height: 100%;
}

.btn-primary {
    background-color: var(--primary);
    color: var(--white);
    display: flex;
   width:25.375rem;
    padding: .875rem 1.25rem .875rem 1.25rem;
    max-width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 800;
    text-align: center;
    transition: all .4s ease-in;
    border: .1rem solid #246BFD;
    border-radius: 6.25rem;
    cursor: pointer;
    @include mobile{
        width: 80vw;
        max-width: 80%;
    }


    &:hover {
        font-weight: 800;

    }

}

.large__text{
    color: #212121;
    font-weight: 800;
    font-family: 'Nunito', sans-serif;
    font-size: clamp(2rem, 10vw , 4rem);


}