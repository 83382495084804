@use '../../styles/mixin' as *;

.creditcard {
    background-color: var(--primary);
    padding: 1.875rem 0.875rem;
    /* 1.875rem 0.875rem */
    border-radius: 1.5rem;
    /* 1.5rem */
    background-image: url('../../../public/blue.svg');
    background-size: contain;
    background-repeat: no-repeat;
    font-family: 'Nunito', sans-serif;
    display: grid;
    gap: 1.875rem;

   
    /* 1.875rem */

    @include tablet {
        width: 70%;
        /* 4.375rem */
    }

    p.account_number {
        font-size: 1.125rem;
        /* 1.125rem */
    }

    @include large-desktop {

        p,
        h3 {
            font-size: 1.25rem;
            /* 1.25rem */
            font-weight: 700;
        }
    }

    p,
    h3 {
        font-size: 0.9375rem;
        /* 0.9375rem */
        font-weight: 700;
    }

    article,
    p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--white);
    }

    p {
        justify-content: flex-start;
        gap: 0.3125rem;
        /* 0.3125rem */
    }
}



.card {
    background-image: url('../../../public/blue.svg');
    width: 23.75rem;
    /* 23.75rem */
    height: 11.25rem;
    /* 11.25rem */
    border-radius: 1.5rem;
    /* 1.5rem */
    background-color: var(--primary);
    padding-block-start: 1.1875rem;
    /* 1.1875rem */
    padding-inline-start: 0.9375rem;
    /* 0.9375rem */
    padding-inline-end: 0.9375rem;
    /* 0.9375rem */
    padding-block-end: 1.1875rem;
    /* 1.1875rem */

    @include mobile {
        width: 100%;
    }
}

.redeem__card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    /* 1.875rem */

    .top {
        h2 {
            color: #FAFAFA;
            font-size: 2rem;
            /* 2rem */
            font-style: normal;
            font-weight: 700;
        }

        p {
            color: #FAFAFA;
            font-size: 1rem;
            /* 1rem */
            font-weight: 400;
            line-height: 1.25rem;
            /* 1.25rem */
        }
    }

    .bottom {
        display: flex;
        gap: 0.75rem;
        /* 0.75rem */
        justify-content: space-between;
        align-items: center;

        h3,
        p {
            color: #FAFAFA;
            font-size: 1rem;
            /* 1rem */
            font-style: normal;
            line-height: 1.25rem;
            /* 1.25rem */
            font-weight: 400;
        }

        h3 {
            font-weight: 500;
        }

        p {
            display: flex;
            gap: 0.75rem;
            /* 0.75rem */
            align-items: center;
        }

        button {
            border-radius: 0.5rem;
            /* 0.5rem */
            background: #FFF;
            display: flex;
            padding: 0.40625rem 0.75rem;
            /* 0.40625rem 0.75rem */
            justify-content: center;
            align-items: center;
            color: var(--primary);
            text-align: center;
            font-size: 1.125rem;
            /* 1.125rem */
            font-weight: 500;
            transition: all 0.2s ease-in-out;
        }
    }
}

.my__card {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.75rem 2.9375rem;

    @include mobile {
        padding: 1rem;
    }

    /* 1.75rem 2.9375rem */

    h1 {
        @include tablet {
            font-size: 1.2rem;
        }

        @include mobile {
            font-size: 1rem;
        }

        color: #FFF;
        font-size: 1.5rem;
        /* 1.5rem */
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    .bottom {
        display: flex;
        gap: 0.75rem;
        /* 0.75rem */
        justify-content: space-between;
        align-items: center;

        p {
            display: grid;
            gap: 0.5rem;
            /* 0.5rem */
            align-items: center;
        }

        .title {
            color: #FFF;
            font-size: 0.625rem;
            /* 0.625rem */
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.02rem;
        }

        .expiry_date,
        .name {
            color: var(--white);
            font-size: 1rem;
            /* 1rem */
            font-weight: 600;
            line-height: 1.4rem;
            /* 1.4rem */
            letter-spacing: 0.02rem;
        }
    }
}

.creditcard__message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5625rem;
    /* 1.5625rem */
    padding: 1.5625rem 0;
    /* 1.5625rem 0 */

    h4 {
        color: #FFF;
        text-align: center;
        font-size: 1.25rem;
        /* 1.25rem */
        font-weight: 700;
        line-height: 120%;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 0.875rem;
        /* 0.875rem */
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
        /* 1.25rem */
    }
}