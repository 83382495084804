@use '../../../styles/mixin' as *;

.accountNumberTableContainer {
    border: 0.0625rem solid var(--French-Grey, #D1D1D8);
}

.title {
    color: var(--greyscale-900, #212121);
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 1.125rem; // Converted from 1.8rem
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.sub__title {
    color: #484848;
    font-family: 'Nunito', sans-serif;
    font-size: 0.875rem; // Converted from 1.4rem
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.02rem;
}

.accountNumberTableItem {
    display: flex;
    gap: 5.5rem; // Converted from 8.8rem
    align-items: center;

    @include mobile {
        flex-wrap: wrap;
        gap: 0.625rem 2.5rem; // Converted from 1rem 4rem
        padding: 1.25rem; // Converted from 2rem
    }

    @include small-desktop {
        gap: 0.625rem 2.5rem; // Converted from 1rem 4rem
        padding: 1.25rem; // Converted from 2rem
        flex-wrap: wrap;
    }

    h5 {
        color: #484848;
        font-family: 'Nunito', sans-serif;
        font-size: 0.875rem; // Converted from 1.6rem
        font-style: normal;
        font-weight: 500;
        line-height: 120%;

        @include mobile {
            font-size: 0.875rem; // Converted from 1.4rem
        }
    }
}

.account__number {
    color: #484848;
    font-family: 'Nunito', sans-serif;
    font-size: 0.875rem; // Converted from 1.6rem
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.02rem;
    cursor: copy;
}
