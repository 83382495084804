.btn.btnn {
  display: flex;
  padding: 11px 14px 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--primary);
  max-width: max-content;
  font-weight: 500;

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
      background: darken($color: #246BFD, $amount: 10);
  }
}

.header,
.list_item {
  display: grid;
  grid-template-columns: minmax(183px, 2fr) repeat(5, minmax(0, 1fr));
  gap: 30px;
  min-width: 700px;



}

.list_item {
  align-items: center;
}

.list__container {
  display: grid;
  gap: 20px;
  position: relative;
}

.type {
  color: #78778B;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.amount,
.date {
  color: #1B212D;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &.debit{
      color: rgba(235, 12, 12, 0.904);
  }
}

.date {
  font-weight: 500;

}

.time {
  color: #78778B;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;

}

.reference {
  color: #78778B;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: wrap;
  overflow-wrap: break-word;


}





// Header
.header {
  h1 {
      color: #929EAE;
      font-size: 12px;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
  }
}

// @keyframes appear {
//   from {
//     opacity: 0;
//     scale: 0.8;
//   }
//   to{
//     opacity: 1;
//     scale: 1;
//   }

// }

// .list__item{
//   animation: appear linear;
//   animation-timeline: view();
//   animation-range:entry 25% cover 50%; ;
//   animation-delay: 3s;

// }