@use '../../../styles/mixin' as *;

.heading {
    display: grid;
    place-content: center;
    margin-block-end: 1.8125rem; /* Converted from 2.9rem */

    h1 {
        color: var(--greyscale-900, #212121);
        text-align: center;
        font-family: 'Nunito', sans-serif;
        font-size: 1.5rem; /* Converted from 2.4rem */
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-block-end: 0.8125rem; /* Converted from 1.3rem */
        max-width: 30ch;

        @include mobile {
            font-size: 1.5rem; /* Converted from 2.4rem */
        }
    }

    p {
        color: var(--greyscale-800, #424242);
        text-align: center;
        font-family: 'Nunito', sans-serif;
        font-size: 1rem; /* Converted from 3.2rem */
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.0125rem; /* Converted from 0.02rem */
    }
}
