@use '../../../styles/mixin' as *;

.applybox {
    display: grid;
    grid-template-columns: 13.375rem 1fr;

    /* 13.375rem 1fr */
    @include mobile {
        display: flex;

    }


    input {
        border-block-end: 0.0625rem solid #BEBCBD;
        border-block-start: 0.0625rem solid #BEBCBD;
        border-inline-start: 0.0625rem solid #BEBCBD;

        border-top-left-radius: 0.75rem;
        /* 0.75rem */
        border-bottom-left-radius: 0.75rem;
        /* 0.75rem */
        background: #FFF;
    }

    button {
        border-top-right-radius: 0.75rem;
        /* 0.75rem */
        border-bottom-right-radius: 0.75rem;
        /* 0.75rem */
        border-block-end: 0.0625rem solid #246BFD;
        /* 0.0625rem */
        border-block-start: 0.0625rem solid #246BFD;
        /* 0.0625rem */
        border-inline-end: 0.0625rem solid #246BFD;
        /* 0.0625rem */
        background: #246BFD;
        padding: 0.75rem 1.9375rem;

        @include mobile {
            padding: .75rem;
        }

        display: flex;
        align-items: center;
        color: #FFF;
        font-family: 'Causten' sans-serif;
        font-size: 1rem;
        /* 1rem */
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        transition: all 0.2s ease-in-out;

        @include mobile {
            font-size: .875rem;
        }


        &:hover {
            background: darken($color: #246BFD, $amount: 10);
        }
    }
}