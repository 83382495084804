@use '../../../../styles/mixin' as *;

.fundingsummary__title {
    color: var(--Space-Cadet, #17183B);
    font-family: 'Nunito', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;

    @include mobile {
        font-size: 1rem;
    }
}

.fundingsummary__section {
    p {
        color: var(--Space-Cadet, #17183B);
        font-family: 'Nunito', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.5rem;

        &.total {
            font-weight: 600;
        }
    }

    h4 {
        color: var(--Space-Cadet, #17183B);
        font-family: 'Nunito', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.5rem;
    }
}

.fundingsummary__button {
    display: flex;
    height: 3.625rem;
    padding: 1.5rem 2.5rem;

    @include mobile {
        padding: 1rem;
    }

    justify-content: center;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    border-radius: 6.25rem;
    background: #246BFD;
    color: var(--White, #FFF);
    font-family: 'Nunito',
    sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: 0.01rem;
    width: 100%;
}