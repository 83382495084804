@use '../../../styles/mixin' as *;

.form {
    display: grid;
    gap: 1.9rem;
    width: 58.2rem;
    padding: 1.875rem 5.5625rem 3.1875rem 5.5625rem;
    border-radius: 1.2rem;
    border: 1px solid var(--Light-Gray, #B2B3B3);
    background: var(--gray-gray-1, #FAFAFA);
    @include mobile{
        width: 100%;
        padding: 1.875rem 1.25rem 3.1875rem 1.25rem;
    }

    >p , label {
        color: var(--greyscale-700, #616161);
        font-family: 'Nunito', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.02rem;
    }
}

.confirm {
    padding: 1.5rem 3.375rem 2.75rem 3.375rem;
    border-radius: 1.2rem;
    border: 1px solid #B2B3B3;
    background: #FAFAFA;
    width: 58.2rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    @include mobile{
        width: 100%;
        padding: 1.25rem 1.875rem 2.75rem 1.875rem;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            color: var(--greyscale-700, #616161);
            font-family: 'Nunito', sans-serif;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: 0.02rem;

            &.confirm__item__last {
                font-weight: 900;
                color: #000;
            }
        }

        h3 {
            color: var(--greyscale-900, #212121);
            text-align: right;
            font-family: 'Nunito', sans-serif;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 0.02rem;
        }
    }

    &__container {
        border-block-start: 1px solid #EEE;
        padding-block-start: 1.625rem;
        display: grid;
        gap: 1.5625rem;
    }
}

.pin__form {
    display: grid;
    place-content: center;
    place-items: center;
}

.confirm__save {
    display: grid;
    place-content: center;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    padding-block-start: 1.4375rem;
    cursor: pointer;

    h3 {
        color: var(--greyscale-700, #616161);
        font-family: 'Nunito', sans-serif;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.02rem;
    }
}
