@use '../../styles/mixin' as *;

.message {
  background: var(--white);
  position: relative;
  display: grid;
  padding-block-end: 1.5625rem; // Converted from 2.5rem
  border-radius: 5px;
  width: 18.75rem; // Converted from 40%

  @include small-desktop {
    width: 30rem; // Converted from 60%
    margin: 0 auto;
  }

  @include tablet {
    width: 35rem; // Converted from 70%
    margin: 0 auto;
  }

  @include mobile {
    width: 100%;
    margin: 0 auto;
  }

  .svg {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .image__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block-end: 1.5625rem; // Converted from 2.5rem
    padding-block-start: 4.3125rem; // Converted from 6.9rem
    border-block-end: 1px solid #EEE;
  }

  @include large-desktop {
    width: 36.25rem; // Converted from 58rem
    height: 40rem; // Converted from 64rem
    padding-bottom: 9.0625rem; // Converted from 14.5rem
  }

  .info__container {
    padding: 1.5625rem 6.5rem; // Converted from 2.5rem 5.2rem
    display: grid;
    gap: 1.3125rem; // Converted from 2.1rem

    @include small-desktop {
      padding: 1.5625rem 4.375rem; // Converted from 2.5rem 3.5rem
    }

    @include tablet {
      padding: 1.5625rem 3.125rem; // Converted from 2.5rem 2.5rem
    }

    @include mobile {
      padding: 2rem 2rem; // Converted from 2rem 2rem
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .right {
      text-align: right;
      color: var(--greyscale-900, #212121);
      font-size: 1.125rem; // Converted from 1.8rem
      font-weight: 700;
      line-height: 140%;
      /* 2.52rem */
      letter-spacing: 0.0125rem; // Converted from 0.02rem

      @include mobile {
        font-size: 0.875rem; // Converted from 1.4rem
      }
    }
  }

  .key {
    color: var(--greyscale-700, #616161);
    font-size: 1rem; // Converted from 1.6rem
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.0125rem; // Converted from 0.02rem

    &.total {
      font-weight: 700;
      color: #000;
    }

    @include mobile {
      font-size: 0.8125rem; // Converted from 1.3rem
    }
  }
}
