@use '../.././../styles/mixin' as *;

.card {
    display: flex;

    flex-direction: column;
    border-radius: 12px;
    background: var(--neutral-color-00, #FDFDFD);
    box-shadow: 0px 4px 28px -2px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    gap: 8px;

    &__image__container {
        height: 322px;
        width: 100%;



    }

    &__content__container {
        padding-block-start: 8px;
        padding-inline-start: 16px;
        padding-inline-end: 35px;
        display: flex;
        flex-direction: column;


        gap: .5rem;

        @include mobile {
            padding-inline-start: 1rem;
            padding-inline-end: 1rem;
        }


        h3 {
            color: #00171F;
            font-family: 'Nunito', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            padding-block-end: 4px
        }

        h4 {
            color: #00171F;
            font-family: 'Nunito', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 2px;
        }
    }

}