@use '../../styles/mixin' as *;

.container {
    width: 28%;
    height: max-content;
    background: #fff;
    border-radius: 1.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 28rem;
    // @include desktop{
    //     height: 80%;
    // }

    @media screen and (min-width : 1280px )and (max-width: 1499px  )  {
       height: max-content;

    }

        @media screen and (min-width : 1399px ) {
            padding: 5.375rem 2.5rem;
            padding-block-end: 5rem;

        }

        @media  screen and (min-width : 1700px  ) {
            padding: 6.625rem 3.125rem;
            padding-block-end: 6.25rem;

        }





        @include mobile{
            padding: 2rem 1rem;
            // height: 70%;
            width: 90%;

        }
        @include tablet{
            padding: 2rem 1;
            width: 50%;
        }



    .image__container {
        width: 12.5rem;
        height: 9.375rem;
    }

    .message {
        display: grid;
        gap: 1rem;
        place-items: center;

        h1 {
            color: #246BFD;
            text-align: center;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%
        }

        p {
            color: #212121;
            text-align: center;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: 0.02rem;

            span {
                font-weight: 700;
            }
        }

    }
}


// maxWidth: '58%',
// maxHeight: '5.8rem',
// marginBlockStart: '3.5%'
.button.btn{
    max-width: 16.563rem;
}
.btn{
    max-width: 58%;
    max-height: 3.625rem;
    margin-block-start: 3.5%;



    @include mobile{
        max-width: 90%;

    }

    &.elect{

            border-radius: 6.25rem;
            background: #E9F0FF;
            color: var(--primary);
            text-align: center;
            text-shadow: 4px 8px 24px rgba(36, 107, 253, 0.25);
            font-size: 1rem;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 0.02rem;
            border: 1px solid  #E9F0FF;

    }
}