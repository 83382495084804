@use '../../../styles/mixin' as *;

.cardlist{
    display: grid;
    padding-block-start: 40px;
    justify-content: space-between;
    gap : 40px;
    row-gap: 20px;
    place-content: center;


    @include large-desktop{
        grid-template-columns: repeat(3 , minmax(262px , 33%));
        justify-content: space-between;
        column-gap : 150px;
        row-gap: 90px;

    }

    @include desktop {
        grid-template-columns: repeat(3 , minmax(202px , 33%));
        justify-content: space-between;

        column-gap: 50px;
        row-gap: 50px;



    }

    @include small-desktop{
        grid-template-columns: repeat(3 , minmax(202px , 33%));
        justify-content: space-between;

        column-gap: 50px;
        row-gap: 30px;
    }



    @include tablet {
        grid-template-columns: repeat(2 , 1fr);

    }









}