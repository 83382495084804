@use '../../styles/mixin' as *;

.container {

    position: relative;
    display: grid;
    grid-template-columns: minmax(20%, 15.938rem) 1fr; /* Adjusted from 25.5rem */
    background: #fff;

    //mobile
    @include tablet {
        grid-template-columns: 1fr;
        align-items: center;
    }
    @include mobile {
        grid-template-columns: 1fr;
        width: 100%;
    }
}

.content {
    position: relative;
    padding: 2.5rem;
    padding-block-start: 1.875rem;

    @include mobile {
        padding: 1.25rem; /* Adjusted from 2rem */
    }
    @media screen and (max-width: 300px) {
        padding: 0.625rem; /* Adjusted from 1rem */
    }

    &.other__content {
        padding: 0;

        .topmenu__container {
            padding: 0 2.5rem;
            padding-block-end: 1.25rem; /* Adjusted from 2rem */
            padding-block-start: 1.25rem; /* Adjusted from 2rem */
            border-block-end: 0.0625rem solid #eeeeee; /* Adjusted from 1px solid */
            @include mobile {
                padding: 1rem;
            }
        }
    }
}

.topmenu__container {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
}

.sidebar {
    @include tablet {
        display: none;
    }
    @include mobile {
        display: none;
    }
    position: relative;
}
