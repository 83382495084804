@use '../../styles/mixin' as *;

@function h($value) {
    @return #{$value / (800 / 900)}vh;
}

.wallettransfer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    @include mobile {
        padding-inline-start: 1.25rem; /* Converted from 2rem */
        padding-inline-end: 1.25rem; /* Converted from 2rem */
    }

    h2 {
        margin-block-start: 2rem; /* Converted from 4.05 */
        margin-block-end: h(2.65); /* Converted from 5.3 */
        font-weight: 700;
        font-size: clamp(1rem, 3.125vw, 1.5rem); /* Converted from clamp(1.6rem , 5vw , 2.4rem) */
        color: #212121;
        font-family: 'Nunito', sans-serif;
        @include mobile {
            text-align: center;
        }
    }



    &__middle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile{
            gap: 1.5rem

        }
        gap: 3rem; /* Converted from 4.8rem */
        margin-block-end: h(2.75); /* Converted from 5.5 */
    }
}

.selectwallet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.wallettransfer__pin {
    color: var(--greyscale-900, #212121);
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin-block-end: h(1.1875); /* Converted from 1.9rem */


    /* 3.52rem */



    &__text {
        color: var(--greyscale-900, #212121);
        text-align: center;
        font-family: 'Nunito', sans-serif;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 2.52rem */
        letter-spacing: 0.02rem;
    }
}

.back__button {
    margin-block-start: h(2.15); /* Converted from 4.3 */
    margin-inline-start: h(2.455); /* Converted from 4.91 */
    margin-block-end: h(2.7); /* Converted from 5.4 */

    @include mobile {
        padding-inline-start: 0;
    }
}
