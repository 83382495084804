:root{
    --white: #ffff;
    --black: #000000;
    --gray-scale-text:#212121;
    --gray-scale-text-light:#424242;
    --gray-text: #929EAE;
    --dark-text: #1B212D;
    --text-1: #78778B;
    --gray-bg:#F8F8F8;
    --gray-light-bg: #EBE8E8;
    --gray-light : #FAFAFA;
    --primary: #246BFD;
    --primary-light:#E9F0FF;
    --secondary:#29A073;
    --bold: 600;
    --semi-bold:500;









}