@use '../../styles/mixin' as *;

.beneficiaries {
    padding-block-start: 3.438rem;
    padding-block-end: 3.438rem;
    padding-inline-start: 6.25rem;
    padding-inline-end: 5.2rem;
    @include tablet{
        padding: 1.25rem;
    }
    @include mobile{
        padding: 0.62rem;
    }

    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2.4rem;
        @include tablet{
            gap: 1.25rem;
        }
        @include mobile {
            grid-template-columns: 1fr;
            gap: 0.938rem;
        }

    }
}

.item {
    display: flex;
    align-items: center;
    gap: 1.625rem;
    padding-block-start: 1.3rem;
    padding-block-end: 1.7rem;
    padding-inline-start: 1.3rem;
    padding-inline-end: 1rem;
    background-color: var(--white);
    box-shadow: 0px 4px 60px 0px rgba(4, 6, 15, 0.05);
    border-radius: 1.6rem;
    cursor: pointer;
    @include tablet{
        gap: 1.3rem;
    }
    @include mobile{
        gap: 0.8rem;
    }


    &__left {
        background-color: #FFFEE0;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include tablet{
            width: 3.75rem;
            height:3.75rem;
        }
        @include mobile{
            width: 3.75rem;
            height: 3.75rem;
        }


        img {
            width: 3.75rem;
            height: 3.75rem;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        gap: .37rem;

        .item__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: .625rem;

            h3,
            p {
                color: var(--greyscale-900, #212121);
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 140%;
                letter-spacing: 0.02rem;
                @include desktop{
                    font-size: 0.938rem;
                }
                @include tablet{
                    font-size: .875rem;
                }
                @include mobile{
                    font-size: .654rem;
                }
            }
        }

        .item__bottom {
            p {
                color: #616161;
                font-size: 1rem;
                font-weight: 500;
                line-height: 140%;
                letter-spacing: 0.02rem;
                @include desktop{
                    font-size: .875rem;
                }
                @include tablet{
                    font-size: .75rem;
                }
                @include mobile{
                    font-size: 0.688rem;
                }
                @include small-desktop{
                    font-size: 0.813;
                }
            }
        }
    }
}

