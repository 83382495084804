.dropdown__container {
    display: grid;
    gap: 0.625rem; /* 0.625rem */
    width: 100%;

    .label {
        color: var(--text-color-text-1, #1B212D);
        font-size: 1rem; /* 1rem */
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .input__container {
        display: flex;
        padding: 0.90625rem 1.125rem 0.84375rem 1.25rem; /* 0.90625rem 1.125rem 0.84375rem 1.25rem */
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 0.625rem; /* 0.625rem */
        border: 0.0625rem solid #929EAE; /* 0.0625rem */
        &.dirty {
            border: 0.0625rem solid var(--primary); /* 0.0625rem */
        }
    }

    input {
        background: inherit;
        outline: none;
        border: none;
        color: #000;
        font-size: 0.875rem; /* 0.875rem */
        font-style: normal;
        font-weight: 500;
        width: 100%;
    }
}

.input__container {
    display: grid;
    gap: 0.625rem; /* 0.625rem */

    .label {
        color: var(--text-color-text-1, #1B212D);
        font-size: 1rem; /* 1rem */
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .input__input {
        display: flex;
        padding: 0.9375rem 1.5625rem 1rem 1.25rem; /* 0.9375rem 1.5625rem 1rem 1.25rem */
        align-items: center;
        gap: 1.5625rem; /* 1.5625rem */
        border-radius: 0.625rem; /* 0.625rem */
        color: #000;
        font-size: 0.875rem; /* 0.875rem */
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background-color: inherit;
    }
}


.select__dropdown{

    div{
        font-size: .875rem !important;
        div{
            font-weight: 500;
        }

    }
}