@use '../../../../styles/mixin' as *;

.token__input {
    font-family: 'Nunito', sans-serif;
    font-size: 1.5rem; /* 1.5rem */
    color: #212121;
    border: 0.0625rem solid #EEE; /* 0.0625rem */
    outline: none;
    display: grid;
    width: 3.5134375rem; /* 3.5134375rem */
    height: 3.125rem; /* 3.125rem */
    border-radius: 0.75rem; /* 0.75rem */
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    box-shadow: 0 0.125rem 0.25rem rgba(238, 238, 238, 0.7); /* 0 0.125rem 0.25rem */

    @include mobile {
        width: 2.5rem; /* 2.5rem */
        height: 2.5rem; /* 2.5rem */
        font-size: 1.125rem; /* 1.125rem */
    }

    background: var(--greyscale-50, #FAFAFA);

    &.active {
        border: 0.0625rem solid var(--primary); /* 0.0625rem */
        font-size: 1.25rem; /* 1.25rem */
        background: rgba(36, 107, 253, 0.08);
    }
}

.token__container {
    display: flex;
    gap: 0.875rem; /* 0.875rem */

    @media screen and (max-width: 400px) {
        gap:  0.55rem ;
    }
}

.token__label {
    color: var(--text-color-text-1, #1B212D);
    font-size: .875rem !important; /* 1rem */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.token {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}
