@use '../../styles/mixin' as *;

.jaralink {
    display: flex;
    gap: 1rem; // Converted from 1.6rem
    align-items: center;
    display: inline-flex;
    padding: 1.625rem 2.6875rem 1.5625rem 1.4375rem; // Converted from 2.6rem 4.3rem 2.5rem 2.3rem
    border-radius: 1.25rem; // Converted from 2rem
    background: #FFF;
    width: 25.75rem; // Converted from 38rem
    @include mobile {
        width: 100%;
    }


    box-shadow: 0px 0.25rem 3.75rem 0px rgba(4, 6, 15, 0.05); // Converted from 0px 4px 60px 0px rgba(4, 6, 15, 0.05)
    p {
        color: #212121;
        font-size: 1.4rem; // Converted from 2.4rem
        font-weight: 700;
        line-height: 1.8rem; // Converted from 120%

        @include desktop {
            font-size: 1.375rem; // Converted from 2.2rem
        }
        @include mobile {
            font-size: 1rem; // Converted from 1.6rem
        }
    }

}
